import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import classNames from 'classnames';
import { nav } from '../meta';

export default () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const docs = data.allMarkdownRemark.edges;

        const sections = Object.keys(nav).map(title => ({
          title,
          classes: nav[title].classes,
          funcs: nav[title].funcs,
          types: nav[title].types
        }));

        return (
          <div className="h-full border overflow-auto p-4">
            {sections.map(section => {
              return (
                <div key={section.title} className="py-2">
                  <Link to={section.title === 'Modelo' ? '/' : `/${section.title.toLowerCase()}`} className="">
                    {section.title}
                  </Link>
                  <div className="pl-4">
                    {section.classes.map(cls => {
                      return (
                        <div key={cls} className="hover:text-orange cursor-pointer relative">
                          <div className="kind-class absolute w-4 h-4 text-center">C</div>
                          <Link to={`/${section.title.toLowerCase()}.${cls.toLowerCase()}`} className="pl-6">
                            {cls}
                          </Link>
                        </div>
                      );
                    })}
                    {section.funcs.map(func => {
                      return (
                        <div key={func} className="hover:text-orange cursor-pointer relative">
                          <div className="kind-func absolute w-4 h-4 text-center">F</div>
                          <Link to={`/${section.title.toLowerCase()}.${func.toLowerCase()}`} className="pl-6">
                            {func}
                          </Link>
                        </div>
                      );
                    })}
                    {section.types.map(type => {
                      return (
                        <div key={type} className="hover:text-orange cursor-pointer relative">
                          <div className="kind-type absolute w-4 h-4 text-center">T</div>
                          <Link to={`/${section.title.toLowerCase()}.${type.toLowerCase()}`} className="pl-6">
                            {type}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          html
          headings {
            value
          }
        }
      }
    }
  }
`;
