import React from 'react';
import MDSider from './MDSider';
import GuideSider from './GuideSider';
import { Link } from 'gatsby';

export default props => {
  const pathname = props.location.pathname;
  const inApi = pathname.startsWith('/modelo') || pathname === '/';

  return (
    <div className="overflow-hidden h-screen">
      <div className="static pin-l pin-t pin-r h-12 bg-grey-lighter flex justify-start items-center">
        <div className="px-2">Modelo</div>
        <Link to="/manual/" className="px-2">
          Manual
        </Link>
        <Link to="/" className="px-2">
          Reference
        </Link>
      </div>
      <div className="flex" style={{ height: 'calc(100% - 48px)' }}>
        <div className="w-1/5">{!inApi ? <GuideSider /> : <MDSider />}</div>

        <div className="w-4/5 overflow-auto px-4 pt-2 pb-4">{props.children}</div>
      </div>
    </div>
  );
};
