import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Location } from '@reach/router';
import unified from 'unified';
import parse from 'rehype-parse';
import Rehype from 'rehype-react';
import classNames from 'classnames';

export default () => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const guides = data.allMarkdownRemark.edges;
        const navs = guides.map(guide => ({
          title: guide.node.frontmatter.title,
          slug: guide.node.fields.slug,
          toc: guide.node.tableOfContents
        }));

        return (
          <Location>
            {({ location }) => {
              return (
                <div className="h-full border overflow-auto p-4">
                  {navs.map(nav => {
                    function a(props) {
                      const isRoot = location.pathname.indexOf(nav.slug) !== -1;
                      const highlight = (location.href || '').indexOf(props.href) !== -1;
                      const isRootNode = props.children.join('') === nav.title;
                      return (
                        <a
                          className={classNames('block', {
                            'text-blue': isRootNode || highlight
                          })}
                          {...props}
                        />
                      );
                    }

                    const isCurrent = location.pathname.indexOf(nav.slug) !== -1;

                    const ast = unified()
                      .use(parse, { fragment: true })
                      .parse(nav.toc);

                    const renderAst = new Rehype({
                      createElement: React.createElement,
                      components: {
                        a
                      }
                    }).Compiler;

                    return (
                      <div key={nav.title}>
                        {!isCurrent && (
                          <Link to={`${nav.slug}`} className="block" style={{ paddingTop: 6 }}>
                            {nav.title}
                          </Link>
                        )}
                        {isCurrent && (
                          <div className="toc">
                            <div>{renderAst(ast)}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </Location>
        );
      }}
    />
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "guide" } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          tableOfContents
          frontmatter {
            title
          }
          headings {
            value
          }
        }
      }
    }
  }
`;
